<template>
  <div style="padding-bottom: 32px;">
    <el-tabs v-model="activeName">
      <el-tab-pane label="广西北海" name="first">
        <beihai-list></beihai-list>
      </el-tab-pane>
      <el-tab-pane label="广西崇左" name="second">
        <chongzuo-list></chongzuo-list>
      </el-tab-pane>
      <el-tab-pane label="广西南宁" name="third">
        <nanning-list></nanning-list>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import BeihaiList from "@/views/passport/guangxi/BeihaiList";
import ChongzuoList from "@/views/passport/guangxi/ChongzuoList";
import NanningList from "@/views/passport/guangxi/NanningList";

export default {
  components: { BeihaiList, ChongzuoList,NanningList },
  data() {
    return {
      activeName: "first",
    };
  },
  methods: {},
};
</script>

<style scoped></style>
